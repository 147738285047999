import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { useSpring, animated } from 'react-spring';
import { mediaAnimationProps } from '../../common';
import { IArticles } from '../../store';

const HeroCardArticles = React.memo(
  ({
    articles,
    visible,
    fontColor,
    backgroundColor,
    swapThumbnailPosition,
    parentId,
  }: IArticles) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));
    return (
      <div className={cn(styles.HeroCardArticles)}>
        {articles && articles.length > 0 && (
          <animated.div style={animateStyle}>
            {articles?.map((article, idx) => {
              const _target = article.target || '_self';
              const _details: string[] = [];
              if (article.author) _details.push(article.author);
              if (article.readingTime) _details.push(article.readingTime);
              return (
                <a
                  key={idx}
                  className={cn(styles.HeroCardArticleContent, {
                    [styles.HeroCardArticleContentSwap]: swapThumbnailPosition,
                  })}
                  href={article.url}
                  target={_target}
                  style={{
                    ...(backgroundColor
                      ? {
                          backgroundColor: `#${backgroundColor}`,
                        }
                      : {}),
                    ...(fontColor ? { color: `#${fontColor}` } : {}),
                  }}
                  onClick={() => {
                    if (
                      article.url &&
                      article.url.includes(window.location.pathname) &&
                      _target === '_self'
                    ) {
                      window.localStorage.setItem(
                        'prevSectionId',
                        `${parentId}`
                      );
                    }
                  }}
                >
                  <div
                    style={{
                      ...(fontColor ? { color: `#${fontColor}` } : {}),
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: article.title as string,
                      }}
                    />
                    {_details.length > 0 && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _details.join(
                            '&nbsp;&nbsp;|&nbsp;&nbsp;'
                          ) as string,
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <img src={article.imageSrc} loading="lazy" />
                  </div>
                </a>
              );
            })}
          </animated.div>
        )}
      </div>
    );
  }
);

export default HeroCardArticles;
