import { CallToAction, FeatureCTA, FeatureIcon, Link } from '~/common/models';
import { Property } from 'csstype';

export interface ISection {
  visible?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
  style?: React.CSSProperties;
}

export interface IBlock extends ISection {
  key: string;
  colSpan: number;
  rowSpan: number;
  contentType: string;
  areas?: IBlock[];
  parentId?: string;
}

export interface ILayout extends IBlock {
  fontColor?: string;
  backgroundColor?: string;
  backgroundColorOpacity?: number;
  backgroundColorGradient?: string;
  padding?: string;
  flowDirection?: string;
  alignment?: string;
  imageSrc?: string;
  indent?: string;
  hasShadow?: boolean;
  position?: string;
  fitContent?: boolean;
  borderRadius?: string;
  divider?: string;
  dividerLength?: string;
  dividerAlignment?: string;
  dividerColor?: string;
  link?: Link;
}

export interface IRte extends IBlock {
  content?: string;
  fontColor?: string;
  disclaimer?: string;
  disclaimerSizeClass?: string;
  icon?: string;
  iconColor?: string;
  iconFill?: string;
  iconSize?: string;
}

export interface ICTAs extends IBlock {
  isStackable?: boolean;
  alignment?: Property.TextAlign;
  contentCtas?: CallToAction[];
}

export interface IKeyFeatures extends IBlock {
  fontColor?: string;
  keyFeatures?: string[];
}

interface IArticle {
  title?: string;
  author?: string;
  readingTime?: string;
  imageSrc?: string;
  url?: string;
  target?: string;
}

export interface IArticles extends IBlock {
  fontColor?: string;
  backgroundColor?: string;
  swapThumbnailPosition?: boolean;
  articles?: IArticle[];
}

export interface IMediaToggle {
  toggleXPosition?: number;
  toggleYPosition?: number;
  toggleHeader?: string;
  toggleContent?: string;
  toggleContentFontColor?: string;
  toggleContentBackgroundColor?: string;
  toggleIconColor?: string;
  toggleIconFillColor?: string;
}

export interface IMediaInfo {
  header?: string;
  content?: string;
}

export enum MediaPinDirectionEnum {
  up = 'Up',
  down = 'Down',
}

export enum MediaPinTextPositionEnum {
  left = 'Left',
  right = 'Right',
  center = 'Center',
}

export interface IMediaPin {
  pinDirection?: keyof typeof MediaPinDirectionEnum;
  pinXPosition?: number;
  pinYPosition?: number;
  pinLength?: number;
  pinColor?: string;
  pinFeatureText?: string;
  pinTextPosition?: keyof typeof MediaPinTextPositionEnum;
  pinTextBackgroundColor?: string;
  hideInMobile?: boolean;
}

export interface IMediaPinKeyframe {
  name: string;
  percent?: string;
  backgroundColor?: string;
  animationProps: Record<string, React.CSSProperties | string>;
}

export interface IImage extends IBlock {
  imageSrc?: string;
  objectFit?: string;
  disclaimer?: string;
  disclaimerFontColor?: string;
  aspectRatio?: string;
}

export interface IFeatureIcons extends IBlock {
  featureIcons?: (FeatureIcon | FeatureCTA)[];
  showDivider?: boolean;
  dividerColor?: string;
}

export interface IMedia extends IBlock, IImage, IFeatureIcons {
  view360Src?: string;
  videoSrc?: string;
  videoAutoplay?: boolean;
  videoLoop?: boolean;
  videoMuted?: boolean;
  videoEmbed?: string;
  featureInfo?: IMediaInfo[];
  featureInfoPosition?: 'Left' | 'Bottom';
  featurePins?: IMediaPin[];
  featureToggles?: IMediaToggle[];
  attachTogglesOnPins?: boolean;
}

export interface ICarousel extends IBlock {
  visibleItemCount?: number;
  items?: TData[];
  showArrows?: boolean;
  showDots?: boolean;
  swipeable?: boolean;
  partialVisible?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  infinite?: boolean;
  pauseOnHover?: boolean;
  arrowColor?: string;
  dotColor?: string;
  activeDotColor?: string;
  borderedArrows?: boolean;
  renderArrowsOutside?: boolean;
  renderDotsOutside?: boolean;
}

interface IAccordionActive {
  idx?: number;
  active?: boolean;
  setActive?: (idx: number) => void;
}

export interface IAccordion extends IBlock, IAccordionActive {
  header?: string;
  fontColor?: string;
  content?: TData;
}

export interface IAccordionList extends IBlock {
  singleMode?: boolean;
  items?: TData[];
}

export type TData =
  | ILayout
  | IRte
  | IArticles
  | ICTAs
  | IKeyFeatures
  | ICarousel
  | IImage
  | IMedia
  | IAccordion
  | IAccordionList;

export interface IHeroCardV2 {
  id?: number;
  gridColumnCount?: number;
  areas?: any[];
  areasMobile?: any[];
  areasTablet?: any[];
}

export interface IRootBlockCommon extends ISection {
  _gridColumnCount: number;
  parentId?: string;
}

export interface IRootBlock extends IRootBlockCommon {
  areas?: TData[];
}

export interface IBlockArea extends IRootBlockCommon {
  area: TData;
}
