import React, {
  AnchorHTMLAttributes,
  ElementType,
  FC,
  HTMLAttributes,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { IRootBlock, IBlockArea, IHeroCardV2, ILayout, TData } from './store';
import { Property } from 'csstype';
import {
  colorBlack,
  hexToRgbA,
  invertHex,
  screenMd,
  screenLg,
} from '~/common/utils';
import {
  HeroCardAccordion,
  HeroCardAccordionList,
  HeroCardArticles,
  HeroCardCarousel,
  HeroCardCtas,
  HeroCardFeatureIcons,
  HeroCardImage,
  HeroCardKeyFeatures,
  HeroCardMedia,
  HeroCardRte,
} from './components';
import uuid from 'react-uuid';
import { mapBackgroundColor, mapBlockAlignment, mapDivider } from './common';
import { useMediaQuery } from 'react-responsive';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';
import { Waypoint } from 'react-waypoint';
import { useNavOffsetHeight } from '~/common/hooks/use-navOffsetHeight';
import { scroller } from 'react-scroll';
import { DynamicTag } from '~/common/components/ui-elements/dynamic-tag';
import { PortalProvider } from 'react-portal-hook';

export const HeroCardBlock = memo((props: IRootBlock) => {
  const { areas } = props;
  return (
    <>
      {areas?.map((area) => (
        <HeroCardBlockArea
          key={area.key}
          {...{ ...{ areas, ...props }, area }}
        />
      ))}
    </>
  );
});

const components = {
  HeroCardRte,
  HeroCardMedia,
  HeroCardCtas,
  HeroCardKeyFeatures,
  HeroCardArticles,
  HeroCardCarousel,
  HeroCardImage,
  HeroCardFeatureIcons,
  HeroCardCustom: HeroCardBlock,
  HeroCardAccordion,
  HeroCardAccordionList,
};

export const BlockComponent = memo((props: TData) => {
  const { isMobile, parentId } = props;
  useRTETooltipPositionCorrector(isMobile);
  const defaultHeightOffset = () => (isMobile ? 55 : 85);
  const offsetHeight = useNavOffsetHeight(isMobile, defaultHeightOffset());
  const [visible, setVisible] = useState(props.visible || false);

  const DynamicComponent = components[props.contentType];

  // callback function to call when event triggers
  const onPageLoad = () => scrollIntoSection();

  // This will run one time after the component mounts
  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const scrollIntoSection = () => {
    // url excluding query string
    const url = new URL(window.location.pathname, window.location.origin);
    const prevSectionId = window.localStorage.getItem('prevSectionId');
    if (
      prevSectionId &&
      parentId === prevSectionId &&
      document.referrer.includes(url.href)
    ) {
      window.localStorage.removeItem('prevSectionId');
      scroller.scrollTo(prevSectionId, {
        smooth: true,
        offset: -offsetHeight,
        duration: 0,
        ignoreCancelEvents: true,
        isDynamic: true,
      });
    }
  };

  return (
    <>
      {components[props.contentType] && (
        <Waypoint
          scrollableAncestor={'window'}
          onEnter={() => {
            setVisible(true);
          }}
        >
          <div className={styles.DynamicComponent}>
            <DynamicComponent {...{ ...props, visible }} />
          </div>
        </Waypoint>
      )}
    </>
  );
});

const HeroCardBlockArea = memo((props: IBlockArea) => {
  const { area, style, _gridColumnCount, parentId, isMobile, isTablet } = props;

  const isLayout = useMemo(
    () => area.contentType === 'HeroCardLayout',
    [area.contentType]
  );
  const getLayout = useMemo(
    () => (isLayout ? (area as ILayout) : ({} as ILayout)),
    [area]
  );

  const hasAreas = useMemo(
    () => area.areas && area.areas?.length > 0,
    [area.areas]
  );

  return (
    <>
      <DynamicTag
        as={getLayout.link ? 'a' : 'div'}
        href={getLayout.link ? getLayout.link.url : undefined}
        target={getLayout.link ? getLayout.link.target || '_self' : undefined}
        key={area.key}
        className={cn(
          styles.HeroCardBlock,
          {
            [styles.HeroCardLayout]: isLayout,
            [styles.HeroCardComponent]: !isLayout,
            [styles.HeroCardLayoutAbsolute]: getLayout.position === 'absolute',
            [styles.FitContent]: getLayout.fitContent,
            [styles.HeroCardLayoutLink]: getLayout.link,
          },
          styles[`HeroCardLayout${getLayout.indent}`],
          `HeroCardLayoutKey-${getLayout.key}`
        )}
        style={{
          width: `${(area.colSpan / 12) * 100}%`,
          ...(getLayout.position
            ? {
                position: getLayout.position as Property.Position,
              }
            : {}),
          ...(getLayout.position === 'absolute' && getLayout.fitContent
            ? {
                height: '100%',
              }
            : {}),
          ...(getLayout.fontColor
            ? {
                color: `#${getLayout.fontColor}`,
              }
            : {}),
          ...mapBackgroundColor(getLayout),
          ...(getLayout.flowDirection
            ? {
                flexDirection:
                  getLayout.flowDirection as Property.FlexDirection,
              }
            : {}),
          ...(getLayout.padding
            ? {
                padding: getLayout.padding,
              }
            : {}),
          ...mapBlockAlignment(getLayout),
          ...(getLayout.hasShadow
            ? {
                boxShadow: `0px 0px 20px 10px ${hexToRgbA(
                  invertHex(getLayout.backgroundColor || colorBlack),
                  '0.33'
                )}`,
              }
            : {}),
          ...(getLayout.borderRadius
            ? {
                borderRadius: `${getLayout.borderRadius}`,
                clipPath: 'border-box',
              }
            : {}),
          ...style,
        }}
      >
        {isLayout ? (
          <>
            {hasAreas && (
              <HeroCardBlock
                areas={area.areas}
                _gridColumnCount={_gridColumnCount}
                parentId={parentId}
              />
            )}
          </>
        ) : (
          <BlockComponent {...{ ...area, isMobile, isTablet, parentId }} />
        )}
      </DynamicTag>
      {getLayout.key &&
        (getLayout.divider ||
          getLayout.divider?.toLocaleLowerCase() !== 'none') && (
          <style>{mapDivider(getLayout)}</style>
        )}
    </>
  );
});

export const HeroCardV2 = React.memo((props: IHeroCardV2) => {
  const { id, gridColumnCount, areas, areasMobile, areasTablet } = props;
  const _gridColumnCount = gridColumnCount || 12;
  const _id = `cid-${id}`;
  const [data, setData] = useState<any[]>();

  const [isTablet, setIsTablet] = useState(
    useMediaQuery(
      { maxDeviceWidth: screenLg },
      undefined,

      (matches) => {
        setIsTablet(matches);
      }
    )
  );

  const [isMobile, setIsMobile] = useState(
    useMediaQuery(
      { maxDeviceWidth: screenMd },
      undefined,

      (matches) => {
        setIsMobile(matches);
      }
    )
  );

  useEffect(() => {
    if (isMobile && areasMobile && areasMobile.length > 0) {
      setData(areasMobile);
    } else if (
      isTablet &&
      ((areasTablet && areasTablet.length > 0) ||
        (areasMobile && areasMobile.length > 0))
    ) {
      if (areasTablet && areasTablet.length > 0) {
        setData(areasTablet);
      } else if (areasMobile && areasMobile.length > 0) {
        setData(areasMobile);
      }
    } else {
      setData(areas);
    }
  }, [isMobile, isTablet]);

  return (
    <div
      key={uuid()}
      id={_id}
      className={cn('full-device-width', styles.HeroCardV2Container)}
    >
      <PortalProvider>
        <div className={styles.HeroCardV2}>
          {data && data?.length > 0 && (
            <HeroCardBlock
              areas={data}
              _gridColumnCount={_gridColumnCount}
              parentId={_id}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          )}
        </div>
      </PortalProvider>
    </div>
  );
});
