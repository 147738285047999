import React, {
  FC,
  AnchorHTMLAttributes,
  ElementType,
  HTMLAttributes,
} from 'react';

interface TagProps {
  as?: ElementType;
}

export const DynamicTag: FC<
  TagProps &
    HTMLAttributes<HTMLOrSVGElement> &
    AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ as: Tag = 'div', ...otherProps }) => {
  return <Tag {...otherProps} />;
};
