import React, { memo } from 'react';
import styles from './index.module.scss';
import { IFeatureIcons } from './../../store';
import { animated, useSpring } from '@react-spring/web';
import { mediaAnimationProps } from '../../common';
import {
  AnimatedNumberIcon,
  CallToActionIcon,
  CallToActionIconV2,
  ImageIcon,
} from '~/common/components';
import { FeatureCTA, FeatureIcon } from '~/common/models';

const HeroCardFeatureIcons = memo((props: IFeatureIcons) => {
  const { visible, featureIcons, showDivider, dividerColor } = props;
  const animateStyle = useSpring(mediaAnimationProps(visible));

  return (
    <>
      {visible && featureIcons && featureIcons.length > 0 && (
        <animated.div
          className={styles.HeroCardFeatureIcons}
          style={animateStyle}
        >
          {featureIcons.map((feature, idx) =>
            feature.contentType === 'ImageIcon'
              ? ImageIcon(idx, {
                  ...feature,
                  showDivider,
                  dividerColor,
                } as FeatureIcon)
              : feature.contentType === 'AnimatedNumberIcon'
              ? AnimatedNumberIcon(idx, {
                  ...feature,
                  showDivider,
                  dividerColor,
                } as FeatureIcon)
              : feature.contentType === 'CtaIcon'
              ? CallToActionIcon(
                  idx,
                  {
                    ...feature,
                    showDivider,
                    dividerColor,
                  } as FeatureIcon,
                  styles.FeatureIconCTA
                )
              : feature.contentType === 'CallToAction'
              ? CallToActionIconV2(
                  idx,
                  { ...feature, showDivider, dividerColor } as FeatureCTA,
                  styles.FeatureIconCTA
                )
              : null
          )}
        </animated.div>
      )}
    </>
  );
});

export default HeroCardFeatureIcons;
