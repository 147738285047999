import React, { memo, useEffect, useState } from 'react';
import { IAccordion } from '../../store';
import cn from 'classnames';
import styles from './index.module.scss';
import { animated, useSpring } from '@react-spring/web';
import { mediaAnimationProps } from '../../common';
import { BlockComponent } from '../..';
import { IconEnum, SvgIcon } from '~/common/components/ui-elements';
import { colorBlack, colorWhite } from '~/common/utils';

const HeroCardAccordion = memo((props: IAccordion) => {
  const { idx, fontColor, header, content, active, setActive, visible } = props;

  const animateStyle = useSpring(mediaAnimationProps(visible));
  const [expand, setExpand] = useState(active);

  useEffect(() => setExpand(active), [active]);

  const handleToggle = () => {
    if (idx && setActive) {
      setActive(idx);
    } else {
      setExpand(!expand);
    }
  };

  return (
    <div key={idx} className={cn(styles.HeroCardAccordion)}>
      <animated.div
        style={{
          ...animateStyle,
          ...{
            color: `#${fontColor || colorBlack}`,
            borderBottom: `1px solid #${fontColor || colorBlack}`,
          },
        }}
        className={styles.AccordionHeader}
        onClick={handleToggle}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: header as string,
          }}
        />
        <SvgIcon
          className={styles.ToggleIcon}
          type={expand ? IconEnum.chevronUp : IconEnum.chevronDown}
          color={`#${fontColor || colorBlack}`}
          size={1.2}
          strokeWidth={1}
          onClick={handleToggle}
        />
      </animated.div>
      {expand && content && (
        // <animated.div
        //   style={{ ...animateStyle }}
        //   className={styles.MediaAccordionContent}
        //   dangerouslySetInnerHTML={{
        //     __html: content as string,
        //   }}
        // />
        <BlockComponent {...{ ...content, visible }} />
      )}
    </div>
  );
});

export default HeroCardAccordion;
