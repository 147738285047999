import React, { Fragment, memo, useEffect, useState } from 'react';
import { IAccordionList } from '../../store';
import cn from 'classnames';
import styles from './index.module.scss';
import { BlockComponent } from '../..';

const HeroCardAccordionList = memo((props: IAccordionList) => {
  const { items, singleMode, visible } = props;
  const [activeIdx, setActiveIdx] = useState(1);

  const handleSetActive = (idx: number) => {
    setActiveIdx(activeIdx === idx ? -1 : idx);
  };

  return (
    <div className={cn(styles.HeroCardAccordionList)}>
      {items &&
        items.length > 0 &&
        items.map((item, idx) => (
          <Fragment key={idx + 1}>
            <BlockComponent
              {...{
                ...item,
                ...{
                  ...{ visible, idx: idx + 1 },
                  ...(singleMode
                    ? {
                        active: activeIdx === idx + 1,
                        setActive: handleSetActive,
                      }
                    : {
                        active: idx + 1 === 1,
                      }),
                },
              }}
            />
          </Fragment>
        ))}
    </div>
  );
});

export default HeroCardAccordionList;
