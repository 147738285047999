import React, { CSSProperties, forwardRef } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { TooltipDisclaimer } from '~/common/components/ui-elements';
import { colorWhite } from '~/common/utils';
import { IImage } from './../../store';
import { Property } from 'csstype';
import { mapAspectRatio } from '../../common';

const HeroCardImage = forwardRef<
  HTMLDivElement,
  IImage & { style?: CSSProperties }
>((props, ref) => {
  const { imageSrc, disclaimer, disclaimerFontColor, objectFit, aspectRatio } =
    props;

  const renderDisclaimer = () => {
    if (!disclaimer) return <></>;
    return (
      <TooltipDisclaimer className={cn(styles.DisclaimerOnImage)}>
        <span
          dangerouslySetInnerHTML={{
            __html: disclaimer as string,
          }}
          style={{ color: `#${disclaimerFontColor || colorWhite}` }}
        />
      </TooltipDisclaimer>
    );
  };

  return (
    <div
      ref={ref || null}
      className={styles.HeroCardImage}
      style={{
        ...mapAspectRatio(aspectRatio),
        ...(props.style ? props.style : {}),
      }}
    >
      <img
        src={imageSrc}
        loading="lazy"
        style={{
          ...(objectFit ? { objectFit: objectFit as Property.ObjectFit } : {}),
        }}
      />
      {renderDisclaimer()}
    </div>
  );
});

export default HeroCardImage;
