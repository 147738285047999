import React, { memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { IconEnum, TooltipDisclaimer } from '~/common/components/ui-elements';
import {
  colorBlack,
  colorPitchBlack,
  colorWhite,
  delay,
  firstLetterToUpper,
  hexToRgbA,
} from '~/common/utils';
import { useSpring, animated } from 'react-spring';
import {
  IMediaPin,
  IMediaPinKeyframe,
  IMedia,
  IMediaInfo,
  MediaPinDirectionEnum,
  IMediaToggle,
} from './../../store';
import { SvgIcon } from '~/common/components/ui-elements/svg-icon';
import { mapAspectRatio, mediaAnimationProps } from './../../common';
import { Property } from 'csstype';
import { HeroCardImage, HeroCardFeatureIcons } from '..';

const MediaPinKeyframe = memo(
  ({ name, percent, backgroundColor, animationProps }: IMediaPinKeyframe) => {
    const toCss = (cssObject: React.CSSProperties | string) =>
      typeof cssObject === 'string'
        ? cssObject
        : Object.keys(cssObject).reduce((accumulator, key) => {
            const cssKey = key.replace(/[A-Z]/g, (v) => `-${v.toLowerCase()}`);
            const cssValue = (cssObject as any)[key]
              .toString()
              .replace("'", '');
            return `${accumulator}${cssKey}:${cssValue};`;
          }, '');
    const cssRules = Object.entries(animationProps).map(
      ([key, value]) => `${key}: ${toCss(value)}`
    );
    return (
      <style>
        {`.${name}::before { animation: ${name} 1.5s forwards;
        background-color: ${backgroundColor}; }`}
        {`.${name}::after { border-color: ${backgroundColor}; }`}
        {`@keyframes ${name} {${percent || ''} {${cssRules.join(';\n')}}}`}
      </style>
    );
  }
);

const MediaPinToggle = memo(
  ({
    idx,
    visible,
    active,
    setActive,
    pin,
    ...toggle
  }: IMediaToggle & {
    idx: number;
    visible?: boolean;
    active: number;
    setActive: React.Dispatch<React.SetStateAction<number>>;
    pin: IMediaPin;
  }) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));

    const handleClick = () => {
      setActive(active === idx ? -1 : idx);
    };

    return (
      <>
        <animated.div
          style={{
            ...animateStyle,
            ...(toggle.toggleContentBackgroundColor
              ? {
                  backgroundColor: hexToRgbA(
                    `#${toggle.toggleContentBackgroundColor}`,
                    '0.75'
                  ),
                }
              : {}),
            ...(toggle.toggleContentFontColor
              ? {
                  color: `#${toggle.toggleContentFontColor}`,
                }
              : {}),
          }}
          className={cn(styles.MediaPinToggleContent, {
            [styles.MediaPinToggleContentDown]:
              pin.pinDirection?.toLowerCase() ===
              MediaPinDirectionEnum.down.toLowerCase(),
            [styles.MediaPinToggleContentActive]: idx === active,
          })}
          dangerouslySetInnerHTML={{
            __html: toggle.toggleContent as string,
          }}
        />
        <div key={idx} className={styles.MediaPinToggle}>
          <SvgIcon
            className={styles.MediaPinToggleIcon}
            type={idx === active ? IconEnum.minus : IconEnum.plus}
            color={`#${toggle.toggleIconColor || pin.pinColor || colorBlack}`}
            size={0.67}
            strokeWidth={1.5}
            onClick={handleClick}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent:
                pin.pinDirection?.toLowerCase() ===
                MediaPinDirectionEnum.down.toLowerCase()
                  ? 'start'
                  : 'end',
            }}
          />
        </div>
      </>
    );
  }
);

const MediaPin = memo(
  (props: {
    pin: IMediaPin;
    idx: number;
    id: string;
    visible?: boolean;
    toggle?: IMediaToggle;
    active: number;
    setActive: React.Dispatch<React.SetStateAction<number>>;
  }) => {
    const { pin, idx, id, visible, toggle, active, setActive } = props;
    const animateStyle = useSpring(mediaAnimationProps(visible));
    const txtStyle = {
      ...{
        color: `#${pin.pinColor || colorBlack}`,
      },
      ...(pin.pinTextBackgroundColor
        ? {
            backgroundColor: hexToRgbA(
              `#${pin.pinTextBackgroundColor}`,
              '0.75'
            ),
            borderRadius: '7px',
          }
        : {
            padding: '0px',
          }),
    };

    return (
      <div>
        <MediaPinKeyframe
          name={`MediaPin${id}`}
          percent={'100%'}
          backgroundColor={`#${pin.pinColor || colorBlack}`}
          animationProps={
            pin.pinDirection?.toLowerCase() ===
            MediaPinDirectionEnum.up.toLowerCase()
              ? { top: `-100%`, height: `100%` }
              : { height: `100%` }
          }
        />
        <div
          className={`${`MediaPin${id}`} ${cn(styles.MediaPin, {
            [styles.MediaPinUp]:
              pin.pinDirection?.toLowerCase() ===
              MediaPinDirectionEnum.up.toLowerCase(),
          })}`}
          style={{
            height: `${pin.pinLength || 0}%`,
            top: `${pin.pinYPosition || 0}%`,
            left: `${pin.pinXPosition || 0}%`,
          }}
        >
          <animated.div
            className={cn(
              styles.MediaPinText,
              styles[`MediaPinText${firstLetterToUpper(pin.pinTextPosition)}`],
              {
                [styles.DefaultDisclaimerColor]: [
                  colorBlack,
                  colorPitchBlack,
                ].includes(pin.pinColor || ''),
                [styles.MediaPinTextToggle]: toggle,
              }
            )}
            style={{
              ...txtStyle,
              ...animateStyle,
              ...{
                direction: pin.pinTextPosition === 'left' ? 'rtl' : 'unset',
              },
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: (toggle
                  ? `<p>${toggle.toggleHeader}</p>`
                  : pin.pinFeatureText) as string,
              }}
            />
            {toggle && (
              <MediaPinToggle
                key={idx}
                {...{
                  ...toggle,
                  idx,
                  visible,
                  active,
                  setActive,
                  pin,
                }}
              />
            )}
          </animated.div>
        </div>
      </div>
    );
  }
);

const MediaPins = memo(
  ({
    pins,
    visible,
    id,
    featureToggles,
    attachTogglesOnPins,
  }: {
    pins?: IMediaPin[];
    featureToggles?: IMediaToggle[];
    visible?: boolean;
    id: string;
    attachTogglesOnPins?: boolean;
  }) => {
    const [active, setActive] = useState(0);
    return (
      <>
        {pins && pins.length > 0 && (
          <div className={styles.MediaPins}>
            {pins.map((pin, idx) => {
              const toggle =
                attachTogglesOnPins &&
                featureToggles &&
                featureToggles.length > 0 &&
                idx <= featureToggles.length
                  ? featureToggles[idx]
                  : undefined;
              return (
                <MediaPin
                  key={idx}
                  {...{
                    pin,
                    id: `${id}_${idx}`,
                    idx,
                    visible,
                    toggle,
                    active,
                    setActive,
                  }}
                />
              );
            })}
          </div>
        )}
      </>
    );
  }
);

const MediaToggle = memo(
  ({
    idx,
    visible,
    active,
    setActive,
    ...toggleProps
  }: IMediaToggle & {
    idx: number;
    visible?: boolean;
    active: number;
    setActive: React.Dispatch<React.SetStateAction<number>>;
  }) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));

    const handleClick = () => {
      setActive(active === idx ? -1 : idx);
    };

    return (
      <>
        <div key={idx} className={styles.MediaToggle}>
          <animated.div
            style={animateStyle}
            className={cn(styles.MediaToggleContent, {
              [styles.MediaToggleContentActive]: idx === active,
            })}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: toggleProps.toggleHeader as string,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: toggleProps.toggleContent as string,
              }}
            />
          </animated.div>
          <SvgIcon
            className={styles.MediaToggleIcon}
            type={idx === active ? IconEnum.minusCircle : IconEnum.plusCircle}
            color={`#${colorPitchBlack}`}
            fill={`#${colorWhite}`}
            size={1.5}
            strokeWidth={1.5}
            style={{
              top: `${toggleProps.toggleYPosition || 0}%`,
              left: `${toggleProps.toggleXPosition || 0}%`,
            }}
            onClick={handleClick}
          />
        </div>
      </>
    );
  }
);

const MediaToggles = memo(
  ({
    featureToggles,
    visible,
  }: {
    featureToggles?: IMediaToggle[];
    visible?: boolean;
  }) => {
    const [active, setActive] = useState(0);
    return (
      <>
        {featureToggles && featureToggles.length > 0 && (
          <div className={styles.MediaToggles}>
            {featureToggles.map((toggle, idx) => (
              <MediaToggle
                key={idx}
                {...{ ...toggle, idx, visible, active, setActive }}
              />
            ))}
          </div>
        )}
      </>
    );
  }
);

const FeatureInfo = memo(
  ({
    header,
    content,
    idx,
    featureInfoPosition,
  }: IMediaInfo & {
    idx: number;
    featureInfoPosition?: string;
  }) => {
    return (
      <div
        key={idx}
        className={cn(
          styles.feature,
          styles.FeatureInfo,
          styles[`FeatureInfo${featureInfoPosition}`]
        )}
      >
        <div dangerouslySetInnerHTML={{ __html: header as string }} />
        <div dangerouslySetInnerHTML={{ __html: content as string }} />
      </div>
    );
  }
);

const HeroCardMedia = memo((props: IMedia) => {
  const {
    key,
    visible,
    imageSrc,
    disclaimer,
    disclaimerFontColor,
    videoSrc,
    videoAutoplay,
    videoLoop,
    videoMuted,
    videoEmbed,
    view360Src,
    featureIcons,
    featureInfo,
    featureInfoPosition,
    featurePins,
    featureToggles,
    attachTogglesOnPins,
    objectFit,
    aspectRatio,
  } = props;
  const animateStyle = useSpring(mediaAnimationProps(visible));

  const ref = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(videoAutoplay || false);
  const [viewing, setViewing] = useState(false);
  const [videoTime, setVideoTime] = useState(0);

  const mediaRef = useRef<HTMLDivElement>(null);
  const featureInfoRef = useRef<HTMLDivElement>(null);
  const [mediaRefHeight, setMediaRefHeight] = useState(0);

  useEffect(() => {
    const adjustContainerHeight = () => {
      if (
        featureInfoRef.current &&
        mediaRef.current &&
        featureInfoRef.current.scrollHeight > mediaRef.current.scrollHeight
      ) {
        setMediaRefHeight(featureInfoRef.current.scrollHeight + 20);
      }
    };

    adjustContainerHeight();
    window.addEventListener('resize', adjustContainerHeight);

    return () => {
      setMediaRefHeight(0);
      window.removeEventListener('resize', adjustContainerHeight);
    };
  }, [mediaRef.current, featureInfoRef.current]);

  useEffect(() => {
    if (!visible) setViewing(false);
  }, [visible]);

  const handleClick = async (e) => {
    const _this = e.target.tagName === 'VIDEO' ? e.target : ref.current;
    if (_this) {
      if (_this.paused || (!videoAutoplay && _this.ended)) {
        const _delay = 0.3;
        _this.currentTime = videoTime - _delay > 0 ? videoTime - _delay : 0;
        _this.play();
        await delay(_delay * 1000);
      } else {
        setVideoTime(_this.currentTime || 0);
        _this.load();
        _this.pause();
      }
      setPlaying(!_this.paused);
    }
    e.preventDefault();
  };

  const renderDisclaimer = () => {
    if (!disclaimer) return <></>;
    return (
      <TooltipDisclaimer className={cn(styles.DisclaimerOnImage)}>
        <span
          dangerouslySetInnerHTML={{
            __html: disclaimer as string,
          }}
          style={{ color: `#${disclaimerFontColor || colorWhite}` }}
        />
      </TooltipDisclaimer>
    );
  };

  return (
    <>
      {(videoSrc || videoEmbed || view360Src || imageSrc) && (
        <div className={cn(styles.HeroCardMedia)}>
          {videoSrc || videoEmbed ? (
            <>
              {videoEmbed ? (
                <div
                  className={cn(styles.MediaVideoContainer)}
                  style={mapAspectRatio(aspectRatio)}
                  dangerouslySetInnerHTML={{
                    __html: videoEmbed as string,
                  }}
                />
              ) : (
                <>
                  <div
                    className={cn(styles.MediaVideoContainer, {
                      [styles.MediaVideoContainerPlaying]: playing,
                    })}
                    style={mapAspectRatio(aspectRatio)}
                  >
                    <video
                      ref={ref}
                      autoPlay={videoAutoplay || false}
                      loop={videoLoop || false}
                      muted={videoMuted || false}
                      disablePictureInPicture
                      onClick={handleClick}
                      onEnded={(e: any) => {
                        const _this = e.target || ref.current;
                        if (_this) {
                          _this.currentTime = 0;
                          setVideoTime(0);
                        }
                        setPlaying(videoLoop || false);
                      }}
                      preload="auto"
                      src={videoSrc}
                      style={!playing && imageSrc ? { display: 'none' } : {}}
                    />
                    {!playing && imageSrc && (
                      <img
                        src={imageSrc}
                        loading="lazy"
                        onClick={handleClick}
                        style={{
                          ...mapAspectRatio(aspectRatio),
                          ...(objectFit
                            ? { objectFit: objectFit as Property.ObjectFit }
                            : {}),
                        }}
                      />
                    )}
                    {!playing && (
                      <SvgIcon
                        type={IconEnum.play}
                        color="#323334"
                        fill="#323334"
                        size="100%"
                        onClick={handleClick}
                      />
                    )}
                  </div>
                  {renderDisclaimer()}
                </>
              )}
            </>
          ) : (
            <>
              {view360Src ? (
                <>
                  <div
                    className={styles.Media360Container}
                    style={mapAspectRatio(aspectRatio)}
                  >
                    {!viewing && imageSrc ? (
                      <>
                        <img
                          src={imageSrc}
                          loading="lazy"
                          onClick={() => setViewing(true)}
                          style={{
                            ...mapAspectRatio(aspectRatio),
                            ...(objectFit
                              ? { objectFit: objectFit as Property.ObjectFit }
                              : {}),
                          }}
                        />
                        <SvgIcon
                          type={IconEnum.circularArrows}
                          color={`#${colorWhite}`}
                          fill={`#${colorWhite}`}
                          size="100%"
                          onClick={() => setViewing(true)}
                        />
                      </>
                    ) : (
                      <iframe allowFullScreen src={view360Src} />
                    )}
                    {renderDisclaimer()}
                  </div>
                </>
              ) : (
                <HeroCardImage
                  ref={mediaRef}
                  style={{
                    ...(mediaRefHeight && mediaRefHeight > 0
                      ? { height: `${mediaRefHeight}px` }
                      : {}),
                  }}
                  {...props}
                />
              )}
              {featureIcons && featureIcons.length > 0 && (
                <div className={cn(styles.FeatureIconsContainer)}>
                  <HeroCardFeatureIcons {...props} />
                </div>
              )}
              {featureInfo && featureInfo.length > 0 && (
                <div
                  ref={featureInfoRef}
                  className={cn(
                    styles.FeatureInfosContainer,
                    styles[`FeatureInfosContainer${featureInfoPosition}`]
                  )}
                >
                  <div
                    className={cn(
                      styles.FeatureInfos,
                      styles[`FeatureInfos${featureInfoPosition}`]
                    )}
                  >
                    <animated.div style={animateStyle}>
                      {featureInfo.map((feature, idx) => (
                        <FeatureInfo
                          key={idx}
                          {...{ ...feature, idx, featureInfoPosition }}
                        />
                      ))}
                    </animated.div>
                  </div>
                </div>
              )}
              {featurePins && featurePins.length > 0 && (
                <MediaPins
                  {...{
                    pins: featurePins,
                    featureToggles,
                    visible,
                    attachTogglesOnPins,
                    id: key,
                  }}
                />
              )}

              {!attachTogglesOnPins && (
                <MediaToggles
                  {...{
                    featureToggles,
                    visible,
                  }}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
});

export default HeroCardMedia;
