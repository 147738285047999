import React, { Fragment, memo } from 'react';
import { ICarousel } from '../../store';
import cn from 'classnames';
import styles from './index.module.scss';
import { CustomCarousel } from '~/common/components/ui-elements';
import { BlockComponent } from '../..';

const HeroCardCarousel = memo((props: ICarousel) => {
  const {
    visible,
    arrowColor,
    visibleItemCount,
    showArrows,
    showDots,
    swipeable,
    partialVisible,
    autoplay,
    autoplaySpeed,
    infinite,
    pauseOnHover,
    dotColor,
    activeDotColor,
    borderedArrows,
    renderArrowsOutside,
    renderDotsOutside,
    items,
  } = props;

  const breakpoints = {
    desktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 3,
    },
  };

  const renderItems = () => {
    if (!(items && items.length > 0)) return <></>;
    return items.map((item, idx) => (
      <Fragment key={idx}>
        <BlockComponent {...{ ...item, visible }} />
      </Fragment>
    ));
  };

  const showCarouselNavigation = () => {
    if (!(items && items.length > 0)) return false;
    return items.length > (visibleItemCount || 1);
  };

  const centerCarousel = () => {
    if (!(items && items.length > 0)) return false;
    return items.length < (visibleItemCount || 1);
  };

  return (
    <div
      className={cn(styles.HeroCardCarousel, {
        [styles.HeroCardCarouselCenter]: centerCarousel(),
      })}
    >
      <CustomCarousel
        dotClassName={styles.CarouselDots}
        className={styles.CarouselContainer}
        itemClass={cn(styles.CarouselItem, {
          [styles.CarouselItemSingle]: visibleItemCount === 1,
        })}
        responsive={{
          ...breakpoints,
          desktop: { ...breakpoints.desktop, items: visibleItemCount || 1 },
        }}
        swipeable={swipeable}
        arrows={showArrows && showCarouselNavigation()}
        borderedArrows={borderedArrows}
        carouselArrowColor={arrowColor}
        renderButtonGroupInside={!renderArrowsOutside}
        showDots={showDots && showCarouselNavigation()}
        carouselDotColor={dotColor}
        carouselActiveDotColor={activeDotColor}
        renderDotsInside={!renderDotsOutside}
        autoPlay={autoplay}
        autoPlaySpeed={autoplaySpeed}
        infinite={infinite}
        pauseOnHover={pauseOnHover || false}
        partialVisbile={partialVisible}
      >
        {renderItems()}
      </CustomCarousel>
    </div>
  );
});

export default HeroCardCarousel;
