import React, { memo, useEffect, useRef } from 'react';
import { IRte } from '../../store';
import cn from 'classnames';
import styles from './index.module.scss';
import { animated, useSpring } from '@react-spring/web';
import { mediaAnimationProps } from '../../common';
import { colorBlack, colorPrimary, toCamelCase } from '~/common/utils';
import { IconEnum } from '~/common/components/ui-elements';
import ReactDOMServer from 'react-dom/server';
import { SvgIcon } from '~/common/components/ui-elements/svg-icon';

const HeroCardRte = memo((props: IRte) => {
  const {
    disclaimerSizeClass,
    disclaimer,
    content,
    visible,
    fontColor,
    icon,
    iconColor,
    iconFill,
    iconSize,
  } = props;
  const animateStyle = useSpring(mediaAnimationProps(visible));

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const lastChild = ref.current.lastChild as HTMLElement;
      const parentElement = ref.current.parentElement as HTMLElement;
      parentElement.style.textAlign = lastChild?.style?.textAlign;
    }
  }, [ref.current]);

  const htmlString = icon
    ? ReactDOMServer.renderToStaticMarkup(
        <span
          className={`${
            iconSize && iconSize !== 'none'
              ? `${styles.RteIcon} ${iconSize}`
              : ''
          }`}
        >
          <SvgIcon
            type={toCamelCase(icon) as keyof typeof IconEnum}
            color={`${
              iconColor
                ? iconColor.includes('#')
                  ? iconColor
                  : `#${iconColor}`
                : `#${colorPrimary}`
            }`}
            fill={`${
              iconFill
                ? iconFill.includes('#')
                  ? iconFill
                  : `#${iconFill}`
                : `#${colorPrimary}`
            }`}
          />
        </span>
      )
    : '';

  return (
    <animated.div className={cn(styles.HeroCardRte)} style={animateStyle}>
      <div
        ref={ref}
        className={cn(styles.HeroCardRteContent, {
          [styles.ContentWithDisclaimer]: disclaimer,
          [styles.ContentWithIcon]: icon,
        })}
        style={{
          color: `#${fontColor}`,
        }}
        dangerouslySetInnerHTML={{
          __html: `${htmlString}${content}` as string,
        }}
      />
      {disclaimer && (
        <span
          className={cn(
            `tt ${styles.HeaderDisclaimerTrigger} ${
              disclaimerSizeClass && disclaimerSizeClass !== 'none'
                ? disclaimerSizeClass
                : styles.Disclaimer
            }`
          )}
          style={
            fontColor && fontColor !== colorBlack
              ? {
                  color: `#${fontColor || colorPrimary}`,
                }
              : {}
          }
        >
          *
          <span
            className="tt__content"
            dangerouslySetInnerHTML={{
              __html: disclaimer as string,
            }}
          />
        </span>
      )}
    </animated.div>
  );
});

export default HeroCardRte;
